@import "variables";
@import "mediaQueries";
@import "partials/footer";

body {
  font-family: "Work Sans", sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
p {
  font-weight: 300;
}

.btn {
  text-transform: uppercase;
  border-radius: 0;
}

.btn-link-primary {
  color: $primary-red;;
}

.btn-primary {
  background-color: $primary-red;
  border-color: $primary-red;

  &:hover {
    background-color: lighten($primary-red, 10%);
    border-color: lighten($primary-red, 10%);
  }
}

.form-group {
  label {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 1.125rem;
  }

  .custom-select {
    height: 100%;
    border-radius: 0;
    border: 1px solid;
    text-transform: uppercase;
    font-size: 0.875rem;
  }

  .form-control {
    border-radius: 0;
    min-height: 3.75rem;
    border: 1px solid $dark-gray;

    &::placeholder {
      // text-transform: uppercase;
      font-size: 0.875rem;
    }
  }
}

.btn-secondary {
  background-color: $secondary-blue;
  border-color: $secondary-blue;

  &:hover {
    background-color: lighten($secondary-blue, 10%);
    border-color: lighten($secondary-blue, 10%);
  }
}

.btn-tertiary {
  background-color: $tertiary-blue;
  border-color: $tertiary-blue;
  color: #fff;

  &:hover {
    background-color: lighten($tertiary-blue, 10%);
    border-color: lighten($tertiary-blue, 10%);
    color: #fff;
  }
}


nav {
  &.navbar {
    padding-bottom: 0;
    border-bottom: 1px solid #a1a1a1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }

  .navbar-brand {
    width: 200px;

    img {
      width: 100%;
    }

    @include media-xs-and-down {
      min-height: 85px;
      width: 150px;
      margin: auto 0;
      display: flex;
    }

    @include media-xxs-and-down {
      width: 120px;
    }
  }

  .navbar-collapse {
    flex-direction: column;
  }

  .navbar-nav {
    width: 100%;
    justify-content: flex-end;
    margin: 0.25rem 0 0;
  }

  .nav-link {
    padding: 0.25rem 0.5rem;
    color: #000 !important;

    @include media-md-and-down {
      padding: 0.5rem;
    }
  }

  .nav-item,
  .active {
    font-size: 0.75rem;
    padding-bottom: 0;
    margin: 0;
    flex-shrink: 0;
    white-space: nowrap;

    &:hover {
      font-weight: 500;
    }
  }

  .active {
    border-bottom: 8px solid $secondary-blue;
    font-weight: 500;
  }

  .contact-info {
    font-size: 0.875rem;

    p {
      margin: 0;

      &+p {
        font-size: 0.75rem;
        line-height: 13px;
      }
    }
  }

  .btn-signup,
  .btn-signin {
    font-size: 0.875rem;
    width: 200px;
    margin-left: 1.25rem;
    height: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-md-and-down {
      display: none;
    }
  }

  .btn-signin {
    img {
      width: 29px;
      height: 29px;
      margin-left: 0.25rem;
      margin-bottom: -10px;
    }
  }

  .btn-signup.mobile {
    display: none;

    @include media-md-and-down {
      display: inline-block;
      max-width: 80px;
      justify-self: flex-end;
      text-align: center;
      font-size: 11px;
    }
  }

  .btn-signin.mobile {
    display: none;
  }

  .cta-wrapper {
    @include media-md-and-down {
      display: flex;
      flex-direction: row;
    }
  }

  .cta-button {
    display: flex;
    align-items: center;

    @include media-md-and-down {
      width: 100%;
    }
  }

  .arp-products-dropdown {
    position: initial;

    &__title {
      margin: 0;
      padding: 0.25rem 1.5rem;

      a {
        font-size: 1rem !important;
        font-weight: 500 !important;
      }

      .dropdown-item {
        font-weight: 600;
        font-size: 0.875rem;
        padding: 0;

        &:hover {
          color: $secondary-blue;
        }
      }
    }

    .dropdown-menu {
      justify-content: center;
      width: 100vw;
      left: 0;
      margin-top: -2px;
      border-radius: 0;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
      padding: 15px 0;

      &.show {
        display: flex;
      }

      .dropdown-item {
        font-size: 0.875rem;

        &:hover {
          background-color: transparent;
          color: $secondary-blue;
        }
      }
    }

    @include media-md-and-down {
      &__title {
        padding: 0.5rem;
      }

      .dropdown-menu {
        width: 100%;
        flex-direction: column;
        margin: 0;
        margin-left: -0.5rem;
        box-shadow: initial;
      }
    }

  }

}

#navbar {
  &+* {
    margin-top: $navbarHeight;

    @include media-md-and-down {
      margin-top: 73px;
    }
  }
}

.arp-heading {
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  font-size: 2.813rem;
  position: relative;
  z-index: 2;

  &.small {
    font-size: 2.5rem;
  }

  &.smallest {
    font-size: 2.188rem;
  }
}

.arp-subheading {
  text-transform: uppercase;
  font-size: 1.14rem;
  font-weight: 600;
  letter-spacing: 3px;

  &.big {
    font-size: 1.25rem;
    margin: 1rem 0 3rem;
    letter-spacing: 0.25rem;
  }
}

.arp-paragraph {
  font-size: 1.14rem;
  font-weight: 300;
}


.text {
  &-white {
    color: #fff;
  }

  &-red {
    color: $primary-red;
  }

  &-lightblue {
    color: $secondary-blue;
  }

  &-blue {
    color: $tertiary-blue;
  }

  &-black {
    color: #222;
  }

  &-spacing {
    letter-spacing: 4px;
  }

  &-bold {
    font-weight: 600;
  }
}

.red_text {
  color: $primary-red;
}

.blue_text {
  color: #02b1eb;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #495057;
}

.carousel-indicators {
  li {
    margin: 0 0 0 1.25rem;
    border: 1px solid $secondary-blue;
    border-radius: 50%;
    background-color: transparent;
    //border: 1px solid #fff;
    width: 10px;
    height: 10px;

    &.active {
      background-color: $secondary-blue;
    }
  }
}

.carousel-control-prev-icon {
  background-image: none !important;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 28px;
    height: 28px;
    margin: auto;
    border-left: 2px solid black;
    border-top: 2px solid black;
    transform: rotate(-45deg);
  }
}

.carousel-control-next-icon {
  background-image: none !important;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 28px;
    height: 28px;
    margin: auto;
    border-right: 2px solid black;
    border-top: 2px solid black;
    transform: rotate(45deg);
  }
}

.arp-common-hero {
  background-color: $light-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;

  p {
    font-size: 1.125rem;
  }

  &.extended {
    padding: 2rem 0 0.50rem;
  }
}

.modal {
  .modal-content {
    .modal-header {
      padding: 1.3rem 2rem;
      border-bottom: 6px solid #0ab0ec;

      .modal-title {
        margin-top: 5px;
      }
    }

    .modal-body {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;

      .popup-main-content {
        padding: 1.5rem 3rem;

        @include media-sm-and-down {
          padding: 1rem;
        }

        text-align: justify;
      }
    }

    .modal-footer {}
  }

  .btn {
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 400;
  }
}
