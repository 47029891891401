.arp-suscription {

  &__radio {
    display: flex;
    margin: 1rem 0;
  }

  label.radio-inline {
    color: #fff;
    font-size: 15px;
    position: relative;
    cursor: pointer;

    &+.radio-inline {
      margin-left: 1rem;
    }

    input {
      margin: 0 0.5rem 0 0;
      opacity: 0;
      background-color: red;
      border: 1px solid red;

      &:checked {
        &+.radio__helper:after {
          background-color: $secondary-blue;
        }
      }
    }

    .radio__helper {
      &:after {
        position: absolute;
        left: 0;
        top: 0.25rem;
        bottom: 0;
        margin: 0 auto;
        content: '';
        border: 1px solid #fff;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
    }

  }

  .form-control {
    border-radius: 0;
    border-color: transparent;
    height: 45px;
    margin-bottom: 1.125rem;
  }
}

.arp-footer {
  background-color: $dark-gray;
  color: #fff;
  padding: 3rem 0;

  p {
    margin: 0;
  }

  &__heading {
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0 0 0.25rem 0;
  }

  .arp-schedule {
    font-size: 13px;
  }

  &__contact {

    img,
    i {
      margin-right: 12px;
      width: 21px;
    }

    p {
      font-weight: 400;
    }
  }

  &__links {
    text-transform: uppercase;
    font-size: 15px;
    margin: 0.5rem 0;

    a {
      color: #fff;
    }
  }


  .arp-business-info {
    p {
      font-size: 13px;
      line-height: 18px;
    }

    &__logo {
      margin-bottom: 1.5rem;
    }
  }

  .btn_suscribite {
    width: 100% !important;
  }
}

footer {
  a {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
  }
  a:hover {
    color: #a4a4a4;
    text-decoration: none;
  }
}